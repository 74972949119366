import React, { useContext, useEffect, useState } from "react";
import Layout from "@ttl/shared-react-library/src/components/Layout/Layout";
import { getAppConfig, sendMonitoringLogs } from "../../../../common/utils";
import "react-loading-skeleton/dist/skeleton.css";
import { LOCALSTORAGE_KEYS, MENUS, PATH, PERMISSIONS } from "../../../../common/constants";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "@ttl/shared-react-library/src/auth/AuthContext";
import AuthService from "../../../../services/Auth.service";
import AppRoutes from "../../../../routes/AppRoutes";
import { IMenuItem } from "../../../../common/menuList";
import { useAppStore } from "../../../../contexts/app.context";
import { AppService } from "../../../../services/app.services";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import {
  filterMenusByPermission,
  updateMenuActiveStatus,
  updateMenuBadgeCount,
} from "../../../../common/sideMenuUtils";
import { getFormattedPermissions } from "../../../../common/formattingUtils";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import { observer } from "mobx-react-lite";

const AppLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appStore = useAppStore();
  const authService = new AuthService();
  const { tokens } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorObj, setErrorObj] = useState({ state: false, message: "" });
  const [menus, setMenus] = useState<IMenuItem[]>([]);

  //----------------------- SIDE MENU HANDLERS -----------------------//
  const setActiveMenu = (menuList: IMenuItem[]) => {
    try {
      const activeMenus = updateMenuActiveStatus(menuList, location.pathname);
      setMenus([]); //To handle the active state in the side menu, assigning menus to an empty array
      activeMenus && setMenus(activeMenus);
    } catch (error) {
      console.log("AppLayout ~ setActiveMenu ~ error", error);
    }
  };
  const handleMenuClick = (menu: IMenuItem) => {
    if (menu && menu.link) {
      if (!menu.link.startsWith("http")) {
        navigate(menu.link);
      } else {
        setActiveMenu(menus);
      }
    }
    sendMonitoringLogs(`NAVIGATE_TO_MENU_${menu.name}`);
  };
  //----------------------- ----------------- -----------------------//

  const handleChangeCustomer = async (customerId: string) => {
    if (customerId) {
      setIsAdmin(true);
      window?.localStorage?.removeItem?.(LOCALSTORAGE_KEYS.FILTERS);
      navigate(PATH.SWITCH_CUSTOMER, { state: { id: customerId } });
    } else {
      setIsAdmin(false);
      navigate(PATH.VEHICLES);
    }
  };
  /*
    ~ Function to remove the cookie.
    ~ Returns a promise on either cases to execure further process.
 */
  const handleRemoveToken = () => {
    return new Promise<void>((resolve) => {
      authService
        .getToken("")
        .then(() => resolve())
        .catch((error) => {
          console.log("Error removing cookie", error);
          resolve();
        });
    });
  };

  const loadPermissions = () => {
    try {
      setActiveMenu([]);
      authService
        .getPermissions()
        .then((response) => {
          const permissions = getFormattedPermissions(response?.data?.elements);
          if (permissions?.includes(PERMISSIONS.FOLLOWUP)) {
            appStore?.setPermissions(permissions);
            const authorizedMenus = filterMenusByPermission(permissions);
            authorizedMenus && setActiveMenu(authorizedMenus);
          } else {
            setErrorObj({
              state: true,
              message: i18nInstance.t("TTM.followup.error.unAuthorized"),
            });
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
          setErrorObj({ state: true, message: i18nInstance.t("TTM.followup.error.internalError") });
        });
    } catch (error) {
      console.log("loadPermissions ~ error:", error);
    }
  };

  /**
   * useEffect to set the language in the AppService.
   * If the token contains the language, we set the language in the AppService.
   */
  useEffect(() => {
    if (tokens?.profile?.language) {
      AppService.language = tokens.profile.language;
      isLoading && setLoading(false);
    }
  }, [tokens?.profile?.language]);

  useEffect(() => {
    const unreadCount = appStore?.msgUnreadCount || 0;
    const updatedMenus = updateMenuBadgeCount(menus, MENUS.MESSAGE_CENTER, unreadCount);
    updatedMenus && setMenus(updatedMenus);
  }, [appStore?.msgUnreadCount]);

  useEffect(() => {
    if (appStore?.permissions) {
      const authorizedMenus = filterMenusByPermission(appStore?.permissions);
      authorizedMenus && setActiveMenu(authorizedMenus);
    }
  }, [location]);

  /* 
    ~ useEffect to set globalContextProperty for analytics. 
    ~ if token contains "okta_trimble" in amr, we set internalUser as true else false.
  */
  useEffect(() => {
    try {
      let isInternalUser = false;
      tokens && AppService.authorize(tokens.access_token);
      loadPermissions();
      if (tokens?.profile?.amr?.includes?.("okta_trimble")) {
        isInternalUser = true;
      }
      if (window.DD_RUM) {
        window.DD_RUM.setGlobalContextProperty("isInternalUser", isInternalUser);
      }
    } catch (e) {
      console.log("DD_RUM: error setting globalContextProperty", e);
    }
  }, [tokens?.access_token]);

  return (
    <Layout
      appConfig={getAppConfig()}
      headerTitle={getAppConfig().appTitle}
      menus={menus}
      setSelectedMenu={handleMenuClick}
      showToggleMenu={menus.length > 0}
      iconStylesUrl="https://fonts.googleapis.com/icon?family=Material+Icons"
      showCustomerSwitch={true}
      changeCustomer={handleChangeCustomer}
      removeToken={isAdmin ? handleRemoveToken : undefined}
    >
      {isLoading && <Preloader />}
      {errorObj.state && (
        <div className="app-layout flex-center h-100 w-100">{errorObj.message}</div>
      )}
      {!isLoading && !errorObj.state && <AppRoutes />}
    </Layout>
  );
};
export default observer(AppLayout);
