import { AxiosRequestHeaders } from "axios";
import { getTraceURL } from "../common/serviceUtils";
import { AppService } from "./app.services";

class TraceService {
  getVehicleList() {
    return AppService.axios.get(getTraceURL("terminals"));
  }
  getTraceTypes() {
    return AppService.axios.get(getTraceURL("tracetypes"));
  }
  getTraces(terminalId?: string, startTime?: string, endTime?: string, traceTypes?: string) {
    const traceTypesParam = traceTypes && traceTypes.length > 0 ? `&traceTypes=${traceTypes}` : "";
    return getTraceURL(
      `traces/${terminalId}?startTime=${startTime}&endTime=${endTime}${traceTypesParam}`,
    );
  }
  getTraceDetails(terminalId: string, traceId: string) {
    return AppService.axios.get(getTraceURL(`traces/${terminalId}/${traceId}`));
  }
  getTraceVehiclesList() {
    return getTraceURL("vehicles");
  }
}

export default TraceService;
