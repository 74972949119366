import React, { useEffect, useState } from "react";
import moment from "moment";
import "./DrivingTimeDetails.scss";
import Chip from "../../molecules/Chip/Chip";
import CrewIcon from "../../molecules/CrewIcon/CrewIcon";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import useMobileDetect from "../../../hooks/useMobileDetect/useMobileDetect";
import { ICrew, IDrivingTimeData } from "../../../models/VehicleModel";
import { getFormattedHoursAndMinutes } from "../../../common/utils";
import VehicleService from "../../../services/Vehicle.service";
import StrikeThrough from "../../molecules/StrikeThrough/StrikeThrough";
import TachoStatusIcon from "../../molecules/TachoStatusIcon/TachoStatusIcon";
import moonIcon from "../../../assets/images/open_moon.svg";
import errorIcon from "../../../assets/images/error_icon.svg";
import infoIcon from "../../../assets/images/info.svg";
import DrivingProgressBar from "../../molecules/DrivingProgressBar/DrivingProgressBar";
import {
  COLOR_VALUES,
  CREW_MEMBER_TYPE,
  DRIVING_TYPES,
  DATE_FORMAT,
  DRIVING_TIMES_VALUES,
  DATE_TIME_FORMAT,
  ERROR_CODE,
} from "../../../common/constants";
import { handleError } from "../../../common/helpers/ErrorHandler";
import SkeletonComponent from "../../atoms/Skeleton/SkeletonComponent";
import RestCompensationContainer from "../../molecules/RestCompensationContainer/RestCompensationContainer";

export interface IDrivingTimeDetails {
  driver?: ICrew;
  lastUpdatedTime?: number;
}

const DrivingTimeDetails = (props: IDrivingTimeDetails) => {
  const isMobile = useMobileDetect();
  const [isLoading, setLoading] = useState(true);
  const [drivingTimeData, setDrivingTimeData] = useState<IDrivingTimeData>();
  const [error, setError] = useState<{
    code: number;
    message: string;
  } | null>(null);
  const vehicleService = new VehicleService();
  const [lastUpdatedTime, setLastUpdatedTime] = useState<number>(0);

  const loadDrivingTimeDetails = () => {
    if (props?.driver && props?.driver?.id) {
      setError(null);
      vehicleService
        .getDrivingTimeDetails(props?.driver?.id)
        .then((response) => {
          setDrivingTimeData(response.data);
          setLoading(false);
        })
        .catch((e) => {
          if (e) {
            setError({
              code: e.response?.status,
              message: handleError(e),
            });
          }
          setLoading(false);
          setDrivingTimeData(undefined);
        });
      setLastUpdatedTime(new Date().getTime());
    }
  };

  useEffect(() => {
    loadDrivingTimeDetails();
  }, []);

  useEffect(() => {
    if (lastUpdatedTime && props?.lastUpdatedTime && props.lastUpdatedTime !== lastUpdatedTime) {
      loadDrivingTimeDetails();
    }
  }, [props?.lastUpdatedTime]);

  return (
    <>
      <div className="paper-container title-container mb-1">
        {isLoading ? (
          <div className="p-2 mlr-1">
            <SkeletonComponent height={13} width={310} />
          </div>
        ) : (
          <>
            <div className="title-icon">
              <CrewIcon type={CREW_MEMBER_TYPE.DRIVER} />
            </div>
            <div
              className="title-text"
              title={drivingTimeData?.driver?.name || props?.driver?.name}
            >
              {drivingTimeData?.driver?.name || props?.driver?.name}
            </div>
            <div className="title-action-icon">
              <TachoStatusIcon
                type={drivingTimeData?.driver.drivingType || props?.driver?.drivingType}
                color={COLOR_VALUES.BLACK}
              />
            </div>
          </>
        )}
      </div>
      {!error ? (
        <div className={`driving-details-view ${isMobile && "driving-details-view-mobile"}`}>
          {/* Title */}
          {/* Consecutive driving times */}
          <div className="paper-container">
            <DrivingProgressBar
              showMaxLabel={true}
              isLoading={isLoading}
              data={drivingTimeData?.coupureDriving}
              fillColor={COLOR_VALUES.BLUE_200}
              title={i18nInstance.t("TTM.followup.drivingTimes.consecutiveDrivingTimes")}
            />
          </div>

          {/* Daily driving times */}
          <div className="paper-container pb-3">
            <DrivingProgressBar
              isLoading={isLoading}
              fillColor={COLOR_VALUES.GREEN_100}
              data={drivingTimeData?.dayDriving}
              title={i18nInstance.t("TTM.followup.drivingTimes.dailyDrivingTimes")}
            />
            <div className={`strike-through-row ${!isMobile ? "d-flex" : ""}`}>
              <StrikeThrough
                title={i18nInstance.t("TTM.followup.drivingTimes.dailyReducedRestingTimes")}
                totalElements={DRIVING_TIMES_VALUES.WEEKLY_REDUCED_RESTING_AVAILABLE}
                finished={drivingTimeData?.weeklyDriving.weekShortResting}
                content={DRIVING_TIMES_VALUES.REDUCED_RESTING_HOURS}
                isLoading={isLoading}
              />
              {!isLoading && !isMobile && <div className="divider"></div>}
              <StrikeThrough
                title={i18nInstance.t("TTM.followup.drivingTimes.dailyExtendedDrivingTimes")}
                totalElements={DRIVING_TIMES_VALUES.WEEKLY_EXTENDED_DRIVING_AVAILABLE}
                finished={drivingTimeData?.weeklyDriving.weekLongDriving}
                content={DRIVING_TIMES_VALUES.EXTENDED_DRIVING_HOURS}
                isLoading={isLoading}
              />
            </div>
            <div className="mt-2">
              <DrivingProgressBar
                isLoading={isLoading}
                fillColor={COLOR_VALUES.GREEN_100}
                data={drivingTimeData?.amplitude}
                title={i18nInstance.t("TTM.followup.drivingTimes.amplitudeUsed")}
              />
              {!isLoading && (
                <div className="amplitude-footer">
                  <div className="p-1">
                    {drivingTimeData?.amplitude.start ? (
                      <>
                        {`${i18nInstance.t("TTM.followup.duration.startTime")} - `}
                        <span
                          className="font-weight-bold truncate-text"
                          title={moment(drivingTimeData?.amplitude.start).format(DATE_TIME_FORMAT)}
                        >
                          {moment(drivingTimeData?.amplitude.start).format(DATE_TIME_FORMAT)}
                          {moment(drivingTimeData?.amplitude.start).hours() <
                            DRIVING_TIMES_VALUES.NIGHT_TIME_MAX && (
                            <img src={moonIcon} className="ml-2 mb-1" />
                          )}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <div className="p-1">
                    {drivingTimeData?.amplitude.end ? (
                      <>
                        {`${i18nInstance.t("TTM.followup.duration.maxEndTime")} - `}
                        <span
                          className="font-weight-bold truncate-text"
                          title={moment(drivingTimeData?.amplitude?.end).format(DATE_TIME_FORMAT)}
                        >
                          {moment(drivingTimeData?.amplitude?.end).format(DATE_TIME_FORMAT)}
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="chip-row">
              <Chip
                icon={
                  <TachoStatusIcon type={DRIVING_TYPES.WORKING} color={COLOR_VALUES.BLUE_100} />
                }
                data={getFormattedHoursAndMinutes(drivingTimeData?.dayWorking.duration, "0m")}
                isLoading={isLoading}
              />
              <Chip
                icon={
                  <TachoStatusIcon type={DRIVING_TYPES.WAITING} color={COLOR_VALUES.BLUE_100} />
                }
                data={getFormattedHoursAndMinutes(drivingTimeData?.dayWaiting.duration, "0m")}
                isLoading={isLoading}
              />
              <Chip
                icon={
                  <TachoStatusIcon type={DRIVING_TYPES.RESTING} color={COLOR_VALUES.BLUE_100} />
                }
                data={getFormattedHoursAndMinutes(drivingTimeData?.dayResting.duration, "0m")}
                isLoading={isLoading}
              />
            </div>
          </div>

          {!isLoading && (
            <>
              {/* Weekly rest compensation */}
              {drivingTimeData?.compensations && drivingTimeData?.compensations?.length > 0 && (
                <RestCompensationContainer restCompenstaions={drivingTimeData?.compensations} />
              )}

              {/* Weekly driving times */}
              <div className="paper-container mt-2">
                <DrivingProgressBar
                  showMaxLabel={true}
                  fillColor={COLOR_VALUES.YELLOW_100}
                  data={drivingTimeData?.weeklyDriving}
                  title={i18nInstance.t("TTM.followup.drivingTimes.weeklyDrivingTimes")}
                />
                <div className="driving-times-footer truncate-text">
                  {drivingTimeData?.weeklyDriving.start && (
                    <div>
                      {`${i18nInstance.t("TTM.followup.duration.startOfTheWeek")} : `}
                      {moment(drivingTimeData?.weeklyDriving.start).format(DATE_TIME_FORMAT)}
                    </div>
                  )}
                  {drivingTimeData?.weeklyDriving.end && (
                    <>
                      {`${i18nInstance.t("TTM.followup.duration.endOfTheWeek")} : `}
                      {moment(drivingTimeData?.weeklyDriving.end).format(DATE_FORMAT)}
                    </>
                  )}
                </div>
              </div>
              {/* Bi-weekly driving times */}
              <div className="paper-container mt-2">
                <DrivingProgressBar
                  showMaxLabel={true}
                  fillColor={COLOR_VALUES.BLUE_300}
                  data={drivingTimeData?.biweekly}
                  title={i18nInstance.t("TTM.followup.drivingTimes.biWeeklyDrivingTimes")}
                />
                {drivingTimeData?.biweekly.start && (
                  <div className="driving-times-footer truncate-text">
                    {`${i18nInstance.t("TTM.followup.duration.startOfTheFortnight")} : `}
                    {moment(drivingTimeData?.biweekly.start).format(DATE_FORMAT)}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="driving-details-error">
          <img
            className="driving-details-error-icon"
            src={error.code === ERROR_CODE.NOT_FOUND ? infoIcon : errorIcon}
          />
          <span className="driving-details-error-text">{error.message}</span>
        </div>
      )}
    </>
  );
};

export default DrivingTimeDetails;
