import React, { useEffect, useState } from "react";
import "./Message.scss";
import DateTime from "../DateTime/DateTime";
import { IMessage } from "../../../models/MessageModel";
import { DOCUMENT_PATTERN, MESSAGE_DIRECTION, TEXT_PATTERN } from "../../../common/constants";
import {
  isMessageUnread,
  getMessageDuration,
  isMessageSentNow,
} from "../../../common/messageUtils";
import MessageStatusIcon from "../MessageStatusIcon/MessageStatusIcon";
import DocumentReferenceFormatter from "../../application/molecules/DocumentReferenceFormatter/DocumentReferenceFormatter";
import DocumentNameFormatter from "../../application/molecules/DocumentNameFormatter/DocumentNameFormatter";
import { getAppConfig } from "../../../common/utils";
import { useParams } from "react-router-dom";
import { getFollowupURL, getMessagingURL } from "../../../common/serviceUtils";

export interface IMessageProps {
  message: IMessage;
  showMsgInfo?: boolean;
  showTransition?: boolean;
}

const Message = (props: IMessageProps) => {
  const { message, showMsgInfo, showTransition } = props;
  const attachmentFlag = getAppConfig().messageAttachment;
  const [transition, setTransition] = useState<boolean>(false);
  const params = useParams();
  const getDirectionCls = (direction: string) =>
    direction === MESSAGE_DIRECTION.TO_DEVICE
      ? `msg-sent ${transition ? "msg-transition" : ""}`
      : "msg-received";

  useEffect(() => {
    if (showTransition && isMessageSentNow(message.timestamp)) {
      setTransition(true);
    }
  }, [showTransition]);

  return (
    <div className={`${getDirectionCls(message.direction)}`} id={`message-${message.id}`}>
      <div className="message-container d-flex align-items-center">
        <div className={`msg msg-text ${showMsgInfo ? "msg-tip" : ""}`}>
          {attachmentFlag ? (
            message.direction === MESSAGE_DIRECTION.TO_DEVICE ? (
              <DocumentReferenceFormatter
                text={message.text}
                type={TEXT_PATTERN}
                linkPrefix={getMessagingURL("attachment/downloadUrl?fileKey=")}
              />
            ) : (
              <DocumentNameFormatter
                text={message.text}
                type={DOCUMENT_PATTERN}
                linkPrefix={getFollowupURL(`files/terminals/${params?.unitId}/`)}
              />
            )
          ) : (
            <div className="message-text">{message.text}</div>
          )}
          <div className={`msg-time`}>
            <DateTime displayFormat="hh:mm a" dateTime={message.timestamp} />
            {message.direction === MESSAGE_DIRECTION.TO_DEVICE && (
              <span className="msg-status mx-1">
                <MessageStatusIcon status={message.status} />
              </span>
            )}
          </div>
        </div>
        {isMessageUnread(message) && <span className="msg-unread" />}
      </div>

      {showMsgInfo && (
        <div className="msg-info">
          {getMessageDuration(message.timestamp)}
          {message.sender?.name && (
            <>
              <span className="dot"></span>
              <span title={message.sender?.name}>{message.sender?.name}</span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Message;
