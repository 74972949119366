import React from "react";
import ReactDOM from "react-dom";
import { IVehicleData } from "../../../../models/VehicleModel";
import { ISearchResultPlot } from "../../SearchView/SearchViewModel";
import markerHighlight from "../../../../assets/images/map/marker_highlight.svg";
import TrimbleMaps, { Common, LngLatLike } from "@trimblemaps/trimblemaps-js";

export interface IMapOptions {
  apiKey: string;
  container: string;
  style?: (typeof Common.Style)[keyof typeof Common.Style];
  center: LngLatLike;
  zoom?: number;
  region: (typeof Common.Region)[keyof typeof Common.Region];
  isMobile?: boolean;
  language?: (typeof Common.Language)[keyof typeof Common.Language];
}
export class MapService {
  map: TrimbleMaps.Map = {} as TrimbleMaps.Map;
  initMap(options: IMapOptions) {
    TrimbleMaps.APIKey = options.apiKey;
    options.style = TrimbleMaps.Common.Style.TRANSPORTATION;
    options.zoom = 3;
    this.map = new TrimbleMaps.Map(options);
    this.map.addControl(new TrimbleMaps.NavigationControl());
    return this.map;
  }
  getMap() {
    return this.map;
  }
  setZoom(zoom: number, eventData?: TrimbleMaps.EventData) {
    this.map?.setZoom(zoom, eventData);
  }
  getZoom() {
    return this.map?.getZoom();
  }
  getMarkerElement(id: string) {
    try {
      return document?.getElementById(`marker_${id}`);
    } catch (error) {
      console.log("getMarkerElement ~ error:", error);
    }
  }
  setStyle(
    style: string,
    options?: {
      satelliteProvider?: (typeof Common.SatelliteProvider)[keyof typeof Common.SatelliteProvider];
    },
  ) {
    try {
      this.map?.setStyle(style, options);
    } catch (e) {
      this.logError("Set map style", e);
    }
  }
  toggleTruckRestriction(truckRestriction: TrimbleMaps.TruckRestriction) {
    try {
      truckRestriction && truckRestriction.toggleVisibility();
    } catch (e) {
      this.logError("Toggle truckRestriction", e);
    }
  }
  panTo(lngLat: { lng: number; lat: number } | [number, number], animate: boolean) {
    this.map?.panTo(lngLat, { animate: animate });
  }
  resizeMap() {
    this.map?.resize();
  }
  setCenter(lngLat: { lng: number; lat: number } | [number, number]) {
    try {
      this.map?.setCenter(lngLat);
    } catch (error) {}
  }
  fitBounds(lngLatBounds: TrimbleMaps.LngLatBounds, options?: TrimbleMaps.FitBoundsOptions) {
    try {
      this.map?.fitBounds(lngLatBounds, { ...options, padding: 100, animate: false });
    } catch (e) {
      this.logError("Fit bounds", e);
    }
  }
  plotSearchLocation(plotData: ISearchResultPlot, zoomLevel: number) {
    const { coordinate, popupElement, popupComponent, popupOpen, markerElement, markerIcon } =
      plotData;
    const markers: TrimbleMaps.Marker[] = [];
    try {
      if (coordinate && coordinate?.lon && coordinate?.lat) {
        ReactDOM.render(markerIcon, markerElement);
        const marker = new TrimbleMaps.Marker({
          draggable: false,
          element: markerElement,
        }).setLngLat([coordinate.lon, coordinate.lat]);
        const popup = new TrimbleMaps.Popup();
        if (popupComponent) {
          ReactDOM.render(popupComponent, popupElement);
        }
        popup.setDOMContent(popupElement);
        marker.setPopup(popup);
        marker.addTo(this.map);
        popupOpen && marker.togglePopup();
        this.setZoom(zoomLevel);
        this.panTo(marker.getLngLat(), false); //After setting zoom level, applied pan to bring back to the view port of the specific location
        markers.push(marker);
      }
    } catch (error) {
      this.logError("drawMarkers", error);
    }
    return markers;
  }
  resetMarker(mapMarkers: TrimbleMaps.Marker[]) {
    mapMarkers &&
      mapMarkers.length > 0 &&
      mapMarkers?.forEach((marker) => {
        marker?.getPopup()?.remove();
      });
  }
  removeMarkers(mapMarkers: TrimbleMaps.Marker[]) {
    try {
      if (mapMarkers && mapMarkers.length > 0) {
        mapMarkers.forEach((marker) => marker.remove());
      }
    } catch (error) {
      this.logError("remove markers", error);
    }
  }
  removeMarkerHighlight() {
    try {
      const highlightEle = document?.querySelector?.(".marker-highlight");
      highlightEle?.remove();
    } catch (error) {
      console.log("removeMarkerHighlight ~ error:", error);
    }
  }
  resetMarkerStylings() {
    try {
      this.removeMarkerHighlight();
      const markerClsEle = document?.getElementsByClassName?.("marker-front")?.[0];
      markerClsEle?.classList?.remove?.("marker-front");
    } catch (error) {
      console.log("resetMarkerStyle ~ error:", error);
    }
  }
  highlightSelectedMarker(selectedMarkerId: string, coordinates: TrimbleMaps.LngLat) {
    try {
      this.removeMarkerHighlight();
      const markerEle = this.getMarkerElement(selectedMarkerId);
      const highlightEle = document.createElement("img");
      highlightEle.className = "marker-highlight";
      highlightEle.src = markerHighlight;
      markerEle?.appendChild(highlightEle);
      this.map?.flyTo?.({
        center: coordinates,
      });
    } catch (error) {
      console.log("highlightSelectedMarker ~ error:", error);
    }
  }

  markerToFront(vehicleId: string) {
    try {
      this.resetMarkerStylings();
      const markerEle = this.getMarkerElement(vehicleId);
      markerEle?.classList?.add("marker-front");
    } catch (error) {
      console.log("markerToFront ~ error:", error);
    }
  }
  openPopup(markers: TrimbleMaps.Marker[], id: string) {
    try {
      markers &&
        markers.length > 0 &&
        markers.forEach((marker) => {
          if (marker?.getPopup()?.isOpen()) {
            marker?.getPopup()?.remove();
          }
          const markerEle: HTMLElement = marker.getElement();
          if (markerEle.id == `marker_${id}`) {
            //If the marker not within the viewport, we will pan to the location otherwise we will toggle the pop up alone
            if (!this.map?.getBounds()?.contains(marker?.getLngLat())) {
              this.panTo(marker?.getLngLat(), false);
            }
            if (!marker.getPopup()?.isOpen()) {
              marker?.togglePopup();
            }
          }
        });
    } catch (error) {
      this.logError("open popup", error);
    }
  }
  drawRoute(route: TrimbleMaps.Route) {
    return new Promise((resolve, reject) => {
      try {
        route.addTo(this.map);
        this.map.resize();
      } catch (error) {
        reject(error);
      }
    });
  }
  drawMarkers(markers?: TrimbleMaps.Marker[]) {
    try {
      markers?.forEach((marker) => marker.addTo(this.map));
    } catch (error) {
      this.logError("draw route markers", error);
    }
  }
  locateVehicle(markers: TrimbleMaps.Marker[], selectedVehicle: IVehicleData) {
    if (
      this.map &&
      selectedVehicle &&
      selectedVehicle.location &&
      selectedVehicle.location.coordinate
    ) {
      try {
        this.openPopup(markers, selectedVehicle?.id);
      } catch (error) {
        this.logError("error when locating vehicle in map", error);
      }
    }
  }
  resetMap(mapMarkers: TrimbleMaps.Marker[] | undefined) {
    mapMarkers && this.removeMarkers(mapMarkers);
  }
  setLanguage(locale: string) {
    try {
      this.map?.setLanguage?.(locale);
    } catch (error) {
      this.logError("Error while setting the map language", error);
    }
  }
  logError(label: string, error: any) {
    console.log(label, error);
  }
  removeTraceMarkerHighlight(className: string) {
    try {
      const highlightEle = document?.querySelector?.(`.${className}`);
      highlightEle?.classList?.remove?.(className);
    } catch (error) {
      console.log("removeTraceMarkerHighlight ~ error:", error);
    }
  }
  highlightTraceMarker(traceId: string, className: string) {
    try {
      this.removeTraceMarkerHighlight(className);
      const markerEle = document?.getElementById(`marker_${traceId}`);
      markerEle?.classList?.add(className);
    } catch (error) {
      console.log("highlightTraceMarker ~ error:", error);
    }
  }
}
