import React, { useEffect, useRef, useState } from "react";
import "./MessagesContainer.scss";
import moment from "moment";
import Message from "../Message/Message";
import up from "../../../assets/images/arrow_up.svg";
import { IMessage } from "../../../models/MessageModel";
import { DATE_FORMAT } from "../../../common/constants";
import { getMessageParticipants, getUnreadMessages } from "../../../common/messageUtils";
import MessageInfoBar from "../MessageInfoBar/MessageInfoBar";

export interface IMessagesContainerProps {
  subject: string;
  threadMessageList: IMessage[];
  showTransition: boolean;
}

const MessagesContainer = (props: IMessagesContainerProps) => {
  const { threadMessageList, subject, showTransition } = props;

  const msgRef = useRef<HTMLDivElement>(null);
  const msgParticipants = getMessageParticipants(threadMessageList);

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isThreadActive, setThreadActive] = useState<boolean>();
  const [isCollapsable, setCollapsable] = useState<boolean>(true);

  const handleMessageList = () => {
    isThreadActive
      ? setMessages(threadMessageList)
      : setMessages([threadMessageList?.[0], ...threadMessageList?.slice?.(-3)]);
  };

  /**
   * Conversation threads can be collapsed or expaded only when message length > 6.
   * Initially thread will be in collapsed state if there is no unread message in the thread.
   */
  useEffect(() => {
    if (threadMessageList?.length < 6) {
      setCollapsable(false);
      setThreadActive(true);
    } else {
      setCollapsable(true);
    }
    handleMessageList();
    getUnreadMessages(threadMessageList).length > 0 && setThreadActive(true);
  }, [threadMessageList]);

  /**
   * If thread active, we show all the messages.
   * If thread inactive, we show only the first and the last three messages.
   */
  useEffect(() => {
    handleMessageList();
  }, [isThreadActive]);

  /**
   * Function to toggle the thread.
   */
  const toggleThreadActive = () => {
    try {
      setThreadActive(!isThreadActive);
      msgRef?.current?.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.log("toggleThreadActive ~ error:", error);
    }
  };

  /**
   * Function to check whether to show particular msg's information or not.
   * returns true, if adjacent messages are not in same direction or not in the same time.
   */
  const showMsgInfo = (index: number) => {
    try {
      const currentMsg = messages?.[index];
      const nextMsg = messages?.[index + 1];
      const currentMsgTime = moment(currentMsg?.timestamp).format(DATE_FORMAT);
      const nextMsgTime = moment(nextMsg?.timestamp).format(DATE_FORMAT);
      return currentMsg?.direction !== nextMsg?.direction || currentMsgTime !== nextMsgTime;
    } catch (error) {
      console.log("showMessageInfo ~ error:", error);
    }
  };

  return (
    <div className="msg-container">
      <div
        ref={msgRef}
        className={`msg-subject ${isCollapsable ? "cursor-pointer" : ""}`}
        onClick={isCollapsable ? toggleThreadActive : undefined}
      >
        <span className={"msg-subject-text"} title={subject}>
          {subject}
        </span>
        {isCollapsable && (
          <img className={`arrow ${isThreadActive ? "arrow-up" : "arrow-down"}`} src={up} />
        )}
      </div>
      {messages &&
        messages.length > 0 &&
        messages.map((message: IMessage, index: number) => (
          <React.Fragment key={`msg-${message.id}-${index}`}>
            <Message
              message={message}
              showTransition={showTransition}
              showMsgInfo={showMsgInfo(index)}
            />
            {!isThreadActive && index === messages.length - 4 && (
              <MessageInfoBar
                count={threadMessageList.length - 4}
                participants={msgParticipants}
                handleOnClick={toggleThreadActive}
              />
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default MessagesContainer;
