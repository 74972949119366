import React, { useState } from "react";
import "./TracesMapMobileContainer.scss";
import { PATH } from "../../../../../common/constants";
import TracesMapMobileFooter from "../../../organisms/Traces/TracesMapMobileFooter/TracesMapMobileFooter";
import { useNavigate, useOutletContext } from "react-router-dom";
import { sendMonitoringLogs } from "../../../../../common/utils";
import TracesMap from "../../../organisms/Traces/TracesMap/TracesMap";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { ITraceOutletContext } from "../../../../../models/TraceModel";
import { IMultiSelect } from "../../../../molecules/MultiSelect/MultiSelect";
import PanelHeader from "../../../../global/molecules/PanelHeader/PanelHeader";
import TraceTypeSearch from "../../../organisms/Traces/TraceTypeSearch/TraceTypeSearch";
import PanelContainer from "../../../../global/organisms/PanelContainer/PanelContainer";
import TraceDetails from "../../../../domain/Trace/organisms/TraceDetails/TraceDetails";
import { find } from "lodash";

const TracesMapMobileContainer = () => {
  const traceStore = useTraceStore();
  const navigate = useNavigate();
  const mapContext = useOutletContext<ITraceOutletContext>();
  const [isFilterOpen, setFilterOpen] = useState(false);

  const title = traceStore?.vehiclesList.find((v) => v.id == mapContext?.terminalId)?.title;

  const handleOnFilterOpen = () => {
    sendMonitoringLogs("HISTORY_FILTER_TRACE_TYPES_OPEN");
    setFilterOpen(true);
  };

  const handleOnFilterClose = (filteredTraceTypes?: IMultiSelect[]) => {
    setFilterOpen(false);
    sendMonitoringLogs("HISTORY_FILTER_TRACE_TYPES_CLOSE");
    if (filteredTraceTypes?.length === 0) {
      traceStore?.setTraceFilter({
        ...traceStore.traceFilter,
        traceTypes: [],
      });
    }
  };

  const handleOnBackClick = () => {
    navigate(PATH.TRACES);
  };

  return (
    <div className="trace-map-container-mob">
      {isFilterOpen && (
        <div className="trace-filter-mob">
          <TraceTypeSearch onClose={handleOnFilterClose} />
        </div>
      )}
      <PanelContainer
        header={<PanelHeader title={title} onBackClick={handleOnBackClick} />}
        content={<TracesMap />}
        footer={<TracesMapMobileFooter handleOnFilterOpen={handleOnFilterOpen} />}
      />
      {mapContext?.selectedTrace && (
        <div className="mobile-popup">
          <TraceDetails
            terminalId={mapContext?.terminalId}
            trace={mapContext?.selectedTrace}
            traceType={
              find(traceStore?.traceTypes, { traceType: mapContext?.selectedTrace?.type })?.name
            }
            showTimestamp={true}
            showTraceProperties={true}
            onClose={() => mapContext?.handleSelectedTrace(null)}
          />
        </div>
      )}
    </div>
  );
};

export default TracesMapMobileContainer;
