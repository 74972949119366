import React, { useState } from "react";
import "./DocumentLink.scss";
import MessageService from "../../../../services/Message.service";
import openAttachment from "../../../../assets/images/message/open-attachment.svg";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import ToastWrapper, { IToast, defaultToastObj } from "../ToastWrapper/ToastWrapper";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { TOAST_STYLE } from "../../../../common/constants";
import { sendMonitoringLogs } from "../../../../common/utils";
export interface IDocumentLink {
  link?: string;
  name: string;
  fetchDownloadUrl: string;
}
const DocumentLink = (props: IDocumentLink) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<IToast>(defaultToastObj);
  const [error, setError] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const messageService = new MessageService();
  const handleOnToastClose = () => {
    setToastMsg({ ...toastMsg, showToast: false });
  };
  const handleOnDownloadError = (error: string) => {
    setToastMsg({ showToast: true, message: error, style: TOAST_STYLE.ERROR });
  };
  const documentDownload = () => {
    if (!inProgress) {
      setInProgress(true);
      setLoading(true);
      setError(false);
      messageService
        .getAttachment(props.fetchDownloadUrl)
        .then((response) => {
          const fileLink = document.createElement("a");
          fileLink.href = response.data;
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
          setLoading(false);
          setInProgress(false);
        })
        .catch((e) => {
          setLoading(false);
          setError(true);
          handleOnDownloadError(i18nInstance.t("TTM.followup.messages.attachment.download.error"));
          console.log("Error while downloading file ~ ", e);
          setInProgress(false);
        });
      sendMonitoringLogs("MESSAGE_CONVERSATION_ATTACHMENT_DOWNLOAD");
    }
  };
  return (
    <>
      <div title={props.name} className="attachment-link" onClick={documentDownload}>
        <span className="truncate-text">{props.name}</span>
        <div className="attachment-loader">
          {loading ? <Preloader /> : <img src={openAttachment} />}
        </div>
      </div>
      {error && (
        <div className="attachment-toast">
          <ToastWrapper toastProps={toastMsg} onClose={handleOnToastClose} />
        </div>
      )}
    </>
  );
};

export default DocumentLink;
