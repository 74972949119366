import { AxiosRequestHeaders } from "axios";
import { getFollowupURL, getPermissionsURL } from "../common/serviceUtils";
import { AppService, AuthzService, FetchService } from "./app.services";

class AuthService {
  getActivityOptions() {
    return AuthzService.axios.get(getFollowupURL("v1/activitytypes"));
  }
  getToken(cust_id: string) {
    return AuthzService.axios.get(getFollowupURL(`v1/auth/customer/${cust_id}`));
  }
  getPermissions() {
    return FetchService.axios.get(`${getPermissionsURL("me/v2/permissions")}`, {
      transformRequest: (data?: any, headers?: AxiosRequestHeaders) => {
        if (headers) {
          headers["Authorization"] = `Bearer ${AppService.accessToken}`;
        }
        return data;
      },
    });
  }
}
export default AuthService;
