import moment, { Duration } from "moment";
import { orderBy, find } from "lodash";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { ITask } from "../models/VehicleDetailsModel";
import {
  IActivityType,
  IAddress,
  ICrew,
  ITemperatureZone,
  IVehicleData,
  IViaPoint,
  LngLat,
} from "../models/VehicleModel";
import { ASSET_TYPES, CREW_MEMBER_TYPE, DATE_DISPLAY_FORMAT, MEASUREMENT_UNITS } from "./constants";
import { FixedSizeList } from "react-window";

declare global {
  interface Window {
    appConfig: any;
    DD_RUM: any;
    PL: any; //Pushlet Library window level reference
    onData: any; //Pushlet mechanism - window level onData reference
    onError: any; //Pushlet mechanism - window level onError reference
  }
}
export interface IdentityConfig {
  url: string;
  client: string;
  redirect: string;
  response: string;
  scope: string;
  logoutUrl: string;
  authorizePath: string;
  tokenUrl: string;
}
interface ComponentsConfig {
  headerVersion: string;
}
interface CdnConfig {
  baseUrl: string;
  components: ComponentsConfig;
}
export interface RUMConfigProps {
  scriptSrc: string;
  clientToken: string;
  applicationId: string;
  site: string;
  service: string;
  environment: string;
  trackInteractions: string;
}
export interface MonitoringConfig {
  enabled: string;
  props: RUMConfigProps;
}
export interface OTCookieConfig {
  domainScriptId: string;
}
export interface MapConfig {
  apiKey: string;
  defaultMapLocation: LngLat;
  searchApi?: string;
  routeReportApi?: string;
  cdnBaseUrl: string;
  scriptFile: string;
  styleSheet: string;
}
export interface AutoRefreshConfig {
  enabled: boolean;
  vehiclesViewRefreshInterval: number;
  messagesListRefreshInterval: number;
}
export interface ExternalModulesConfig {
  dms: string;
  places: string;
}

export interface IModules {
  message_center: boolean;
  history: boolean;
  places: boolean;
}

export interface IUmpConfig {
  api: string;
  filter: string;
}
export interface AppConfig {
  appTitle: string;
  api: string;
  identity: IdentityConfig;
  cdn: CdnConfig;
  portalEnv: string;
  cookieConsent: OTCookieConfig;
  vehiclesDataURL: string;
  loadFromAPI: boolean;
  map: MapConfig;
  emergencyDispatch: boolean;
  autoRefresh: AutoRefreshConfig;
  monitoring: MonitoringConfig;
  messageCenter: boolean;
  pushApi: string;
  messageAttachment: boolean;
  notifyViasoundEnabled: boolean;
  appKey: string;
  external_modules: ExternalModulesConfig;
  modules: IModules;
  ump: IUmpConfig;
}

export const getAppConfig = (): AppConfig => {
  return window.appConfig as AppConfig;
};
export const addLeadingZero = (text: number) => {
  return text && text.toString().padStart(2, "0");
};
export const coordinateToDegree = (point: number) => {
  return point / 100000;
};
export const degreeToCoordinate = (deg: number) => {
  return Math.round(deg * 100000);
};
export const metreToKilometre = (value: number) => {
  return value && Math.round(value / 1000);
};
export const getFormattedAddress = (data: IAddress) => {
  let addressStr = "";
  if (data) {
    if (data.street) {
      addressStr += `${data.street}`;
    }
    if (data.number) {
      addressStr += ` ${data.number},`;
    }
    if (data.zipCode) {
      addressStr += ` ${data.zipCode}`;
    }
    if (data.city) {
      addressStr += ` ${data.city}`;
    }
    if (data.state) {
      addressStr += ` ${data.state}`;
    }
    if (data.country) {
      addressStr += ` ${data.country}`;
    }
  }
  return addressStr;
};

export const getBriefAddress = (data: IAddress) => {
  const addressStr = [];
  data && data.city && addressStr.push(data.city);
  data && data.countryCode && addressStr.push(data.countryCode);
  return addressStr.join(", ");
};
export const getCrewMemberByType = (data: ICrew[], type: CREW_MEMBER_TYPE) => {
  if (data && data.length > 0) {
    return data.find((crew: ICrew) => crew.type.toLocaleLowerCase() == type)?.name;
  }
  return;
};

export const getLngLat = (lon: number, lat: number) => {
  return {
    lng: coordinateToDegree(lon),
    lat: coordinateToDegree(lat),
  };
};

const getHours = (time: Duration) => {
  if (time.hours() !== 0) {
    return `${time.hours()}h`;
  } else {
    return "";
  }
};

const getMinutes = (time: Duration) => {
  if (time.minutes() < -1 || time.minutes() > 1) {
    return `${time.minutes()}m`;
  } else {
    return "";
  }
};

export const getTimeDiffernce = (start: number, end: number) => {
  try {
    const diff = moment.duration(moment(start).diff(moment(end)));
    return `${getHours(diff)}${getMinutes(diff)}`;
  } catch (e) {
    console.log("getTimeDifference -->", e);
  }
};

export const getPrefixedTimeDifference = (start: number, end: number) => {
  const timeDiff = getTimeDiffernce(start, end);
  if (timeDiff && parseInt(timeDiff) > 0) {
    return `+${timeDiff}`;
  } else {
    const earlyTime = timeDiff && timeDiff.replace(/-/g, "");
    return earlyTime && earlyTime.length > 0 ? `-${earlyTime}` : earlyTime;
  }
};

/**
 * Method to get the start time in milliseconds for a given day.
 * @param date - Date in DD-MM-YYY format.
 * @returns Start of the day in milliseconds.
 */
export const getStartTimeFromDate = (date: string) => {
  let startTime = "";
  try {
    startTime = moment(date, DATE_DISPLAY_FORMAT, true).startOf("day").valueOf().toString();
  } catch (error) {
    console.log("getStartTimeFromDate ~ error", error);
  }
  return startTime;
};

/**
 * Method to get the end time in milliseconds for a given day.
 * @param date - Date in DD-MM-YYY format.
 * @returns If date is today's date, returns the current time else end of the day in milliseconds.
 */
export const getEndTimeFromDate = (date: string) => {
  let endTime = "";
  try {
    endTime = moment(date, DATE_DISPLAY_FORMAT, true).endOf("day").valueOf().toString();
  } catch (error) {
    console.log("getEndTimeFromDate ~ error", error);
  }
  return endTime;
};

export const getSkeletonArray = () => {
  const item1: any = {};
  item1.id = "skel-1";
  const item2: any = {};
  item2.id = "skel-2";
  return [item1, item2];
};

/**
 * Method to check whether the temperature value is below the low level and above the high level to indicate it as warning
 * @param temp - Current Temperature
 * @param low - Low value
 * @param high - High value
 * @returns - boolean
 */
export const isWarning = (temp: number, low: number, high: number): boolean => {
  let warning = false;
  if (temp == null) return warning;
  warning = temp > (high ?? undefined) || temp < (low ?? undefined);
  return warning;
};
/**
 * 1) Method to prefix label for each zone to differentiate the zone between trailer and truck
 * eg: Trailer Zone1, Vehicle Zone1
 * 2) Assign warning property for each zone during the iteration to use it later for ordering the list
 * @param zones - Array of ITemperatureZone
 * @param prefix - Trailer or vehicle
 * @returns Array of ITemperatureZone with concatenated label and warning
 */
export const formatZones = (zones: ITemperatureZone[], prefix?: string): ITemperatureZone[] => {
  try {
    if (zones && zones.length > 0) {
      return zones.map((zone: ITemperatureZone) => {
        const tempZone = { ...zone };
        const { temp, high, low } = tempZone;
        const zoneLabel = `${i18nInstance.t("TTM.followup.zone")} ${zone.zone}`;
        tempZone.zone = prefix ? `${prefix} ${zoneLabel}` : zoneLabel;
        tempZone.warning = isWarning(temp, low, high);
        return tempZone;
      });
    }
  } catch (error) {
    console.log("Error in preparing zone labels", error);
  }
  return [];
};
export const formatVehicleType = (fleetType?: string): string => {
  return fleetType
    ? fleetType === ASSET_TYPES.TRAILER
      ? i18nInstance.t("TTM.followup.trailer")
      : fleetType === ASSET_TYPES.TRUCK || fleetType === ASSET_TYPES.MOBILE
      ? i18nInstance.t("TTM.followup.vehicle")
      : ""
    : "";
};

/**
 * Method to consolidate all temperature zones of a vehicle(trailer and truck) with the order
 * of warnings at the top and maximum of 3 to restrict the display in the list / card view.
 * Details view will have separate section to show the sensor zones under respective blocks.
 * @param vehicle - the vehicle data
 * @returns - Array of ITemperatureZone
 */
export const getConsolidatedZonesList = (vehicle: IVehicleData): ITemperatureZone[] => {
  let zones: ITemperatureZone[] = [];
  try {
    const trailerZones = formatZones(
      vehicle?.trailer?.zones || [],
      formatVehicleType(vehicle?.trailer?.type),
    );
    const truckZones = formatZones(vehicle?.zones || [], formatVehicleType(vehicle?.vehicle?.type));
    //merge trailer & truck zones to order by warning and truncate the length to 3
    zones = [...trailerZones, ...truckZones] || [];
    //Order by warning property
    zones = orderBy(zones, { warning: true }, ["desc"]);
    //Truncate the array with maximum of 3 zones
    zones.length = 3;
  } catch (error) {
    zones = [];
    console.log("Error when consolidating zones", error);
  }
  return zones;
};
export function getVisibilityEvent(prefix: string) {
  if (prefix) {
    return prefix + "visibilitychange";
  } else {
    return "visibilitychange";
  }
}
// Get the prefix for this browser.
export function getPrefix() {
  // Check to see if the browser supports the unprefixed property.
  if ("hidden" in document) {
    // No prefix needed, return empty.
    return "";
  }

  // Loop through all the possible prefixes.
  const prefixes = ["moz", "ms", "o", "webkit"];

  for (let i = 0; i < prefixes.length; i++) {
    const testPrefix = prefixes[i] + "Hidden";
    if (testPrefix in document) {
      return prefixes[i];
    }
  }

  // The API must not be supported in this browser.
  return "";
}

export const getFormattedDistance = (value: number) => {
  let formattedValue = "";
  if (value > 0 && value < 1000) {
    formattedValue = `${value.toFixed(2)}  ${MEASUREMENT_UNITS.METERS}`;
  } else if (value >= 1000 && value < 100000) {
    formattedValue = `${(value / 1000).toFixed(2)}  ${MEASUREMENT_UNITS.DISTANCE}`;
  } else {
    formattedValue = `${Math.ceil(value / 1000)} ${MEASUREMENT_UNITS.DISTANCE}`;
  }
  return formattedValue;
};

export const getFormattedDispatchEta = (time: string) => {
  let formattedTime = "";
  try {
    const [hours, minutes] = time.split(":");
    formattedTime = moment(
      moment().add({ hour: parseInt(hours), minute: parseInt(minutes) }),
    ).format(`${i18nInstance.t("TTM.dateTimeFormat")}`);
  } catch (error) {}
  return formattedTime;
};

export const getCrewDriver = (crewMembers: ICrew[]) => {
  return crewMembers?.find((crew: ICrew) => crew.type?.toLowerCase() === CREW_MEMBER_TYPE.DRIVER);
};

export const getHoursAndMinutes = (totalMinutes: number) => {
  if (totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  } else {
    return "0:00";
  }
};

export const getFormattedHoursAndMinutes = (totalMinutes?: number, fallbackFormat?: string) => {
  if (totalMinutes && totalMinutes > 0) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = hours && (hours >= 1 ? hours + "h" : "");
    return `${formattedHours ? formattedHours : ""} ${minutes ? minutes + "m" : ""}`;
  } else {
    return fallbackFormat || "0h";
  }
};

export const hasValidCoordinate = (data: ITask | IVehicleData | IViaPoint) => {
  if (
    data &&
    data.location &&
    data.location.coordinate &&
    data.location.coordinate.lon &&
    data.location.coordinate.lat
  ) {
    return true;
  } else {
    return false;
  }
};

export const getRouteStops = (tasks: ITask[]) => {
  const stops: LngLat[] = [];
  try {
    tasks.forEach((task: ITask) => {
      if (hasValidCoordinate(task)) {
        task.via && stops.push(...task.via);
        stops.push(task?.location?.coordinate);
      }
    });
    return stops.map((stop) => {
      return {
        lon: coordinateToDegree(stop.lon),
        lat: coordinateToDegree(stop.lat),
      };
    });
  } catch (e) {}
  return stops;
};

export const getViaPoints = (tasks: ITask[]) => {
  const viaPoints: LngLat[] = [];
  const mappedPoints: IViaPoint[] = [];
  try {
    tasks.forEach((t: ITask) => hasValidCoordinate(t) && t.via && viaPoints.push(...t.via));
    viaPoints.forEach((v: LngLat) => mappedPoints.push({ location: { coordinate: v } }));
  } catch (e) {}
  return mappedPoints;
};

export const mapActivityCode = (tasks: ITask[], activityTypes?: IActivityType[]) => {
  try {
    tasks?.forEach(
      (t: ITask) =>
        (t.activityType.name = find(activityTypes, {
          code: t?.activityType?.code,
        })?.name),
    );
    return tasks;
  } catch (e) {
    console.log("Error in mapping activity codes.");
  }
};

export const setVehicleHeading = (vehicles: IVehicleData[]) => {
  try {
    vehicles.forEach((v: IVehicleData) => {
      if (v && v.location && v.location.heading) {
        v.location.heading = v.speed > 0 ? v.location.heading : undefined;
      }
    });
  } catch (e) {}
  return vehicles;
};

export const getFormattedFleetTitle = (fleetType: string, title: string) => {
  const vehicleType = formatVehicleType(fleetType);
  return `${vehicleType} ${vehicleType ? ": " : ""} ${title}`;
};

/**
 * Scroll utility functions.
 */
export const scrollToElement = (container: string) => {
  try {
    const element = document.getElementById(container);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  } catch (e) {
    console.log("Error while scrolling -->", e);
  }
};

export const scrollToTop = (container: string) => {
  try {
    const element = document.getElementsByClassName(container)[0];
    if (element) {
      element.scroll({ top: 0, behavior: "smooth" });
    }
  } catch (e) {
    console.log("Error while scrolling -->", e);
  }
};

export const virtualizedListScrollTo = (
  id: string,
  list: any[],
  ref: React.RefObject<FixedSizeList<any>>,
) => {
  try {
    const index = list.findIndex((listItem: { id: string }) => listItem.id == id);
    if (index >= 0 && ref && ref.current) {
      ref.current.scrollToItem(index, "smart");
    }
  } catch (error) {
    console.log("virtualizedListScroll ~ error:", error);
  }
};

/**
 *
 * @param element - reference of a DOM element
 * @param offset - specifies offset to the viewport
 * @returns true if element is visible in the viewport.
 * Ref: getBoundingClientRect function
 *    ~ Returns object providing information about the size and position of the element relative to the viewport.
 *    ~ The origin (0,0) is at the top left of the screen.
 *    ~ Positive x increases toward the right and positive y increases toward the bottom
 */

export const isElementInViewport = (
  element: HTMLElement,
  offset?: { offsetTop?: number; offsetBottom?: number; offsetLeft?: number; offsetRight?: number },
) => {
  try {
    const { top, left, bottom, right } = element.getBoundingClientRect();
    return (
      top >= 0 + (offset?.offsetTop || 0) &&
      left >= 0 + (offset?.offsetLeft || 0) &&
      bottom <= window.innerHeight - (offset?.offsetBottom || 0) &&
      right <= window.innerWidth - (offset?.offsetRight || 0)
    );
  } catch (error) {
    console.log("isElementInViewport ~ error:", error);
  }
};
export const loadScript = (url: string, id = "") => {
  return new Promise((resolve, reject) => {
    const ELE_ID = id || "scriptId";
    if (!document.getElementById(ELE_ID)) {
      const HEAD_TAG = document.getElementsByTagName("head")[0];
      const SCRIPT_ELE = document.createElement("script");
      SCRIPT_ELE.src = `${url}`;
      SCRIPT_ELE.type = "text/javascript";
      SCRIPT_ELE.id = ELE_ID;
      SCRIPT_ELE.defer = true;
      SCRIPT_ELE.onload = () => {
        resolve("loaded");
      };
      SCRIPT_ELE.onerror = (error) => {
        reject(error);
      };
      HEAD_TAG.appendChild(SCRIPT_ELE);
    } else {
      resolve("loaded");
    }
  });
};

export const loadStyleSheet = (url: string, id = "") => {
  try {
    return new Promise((resolve, reject) => {
      const ELE_ID = id || "linkId";
      if (!document.getElementById(ELE_ID)) {
        const HEAD_TAG = document.getElementsByTagName("head")[0];
        const LINK_ELE = document.createElement("link");
        LINK_ELE.id = ELE_ID;
        LINK_ELE.type = "text/css";
        LINK_ELE.rel = "stylesheet";
        LINK_ELE.href = `${url}`;
        LINK_ELE.onload = () => {
          resolve("sytlesheet loaded");
        };
        LINK_ELE.onerror = (error) => {
          reject(error);
        };
        HEAD_TAG.appendChild(LINK_ELE);
      } else {
        resolve("sytlesheet loaded");
      }
    });
  } catch (error) {
    console.log("loadStyleSheet ~ error:", error);
  }
};

export const navigateToPath = (path: string) => {
  try {
    window.history.pushState({}, "", `${path}`);
    window.dispatchEvent(new PopStateEvent("popstate"));
  } catch (error) {
    console.log("Error ~ navigateToPath", error);
  }
};

export const sendMonitoringLogs = (action: string, action_value = "") => {
  window?.DD_RUM?.addAction?.(action, { value: action_value });
};
