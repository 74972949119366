import Axios, {
  AxiosError,
  AxiosPromise,
  AxiosResponse,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from "axios";
import { ERROR_CUSTOMER_NOT_SELECTED } from "../common/constants";

export interface IAppService {
  axios: AxiosInstance;
  accessToken: string;
  authorize: (token: string) => AxiosInstance;
  isAuthorized?: boolean;
  language: string;
}

const AppService: IAppService = {} as IAppService;
const axios = Axios.create({ withCredentials: true });
AppService.axios = axios;
AppService.authorize = (token: string): AxiosInstance => {
  AppService.accessToken = token;
  AppService.isAuthorized = true;
  return axios;
};

const requestHandler = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (AppService.isAuthorized && AppService.accessToken) {
    request.headers = request.headers || {};
    request.headers["Authorization"] = "Bearer " + AppService.accessToken;
    request.headers["Accept-Language"] = AppService.language;
  }
  return request;
};
const successHandler = (result: AxiosResponse): AxiosResponse => {
  return result;
};
const errorHandler = (error: AxiosError): AxiosPromise => {
  try {
    const ERR_CODE = (error.response?.data as any)?.errorCode || "";
    if (ERR_CODE === ERROR_CUSTOMER_NOT_SELECTED) {
      window?.location?.reload?.();
    }
  } catch (error) {
    console.log(`Error when parsing the error response within interceptor - ${error}`);
  }
  return Promise.reject(error);
};
AppService.axios.interceptors.request.use(requestHandler);
AppService.axios.interceptors.response.use(successHandler, errorHandler);

const FetchService: IAppService = {} as IAppService;
FetchService.axios = Axios.create();
FetchService.axios.interceptors.response.use(successHandler, errorHandler);

const authzErrorHandler = (error: AxiosError): AxiosPromise => {
  return Promise.reject(error);
};
const AuthzService: IAppService = {} as IAppService;
AuthzService.axios = Axios.create({ withCredentials: true });
AuthzService.axios.interceptors.request.use(requestHandler);
AuthzService.axios.interceptors.response.use(successHandler, authzErrorHandler);
export { AppService, FetchService, AuthzService };
