import { AxiosRequestHeaders } from "axios";
import { getMessagingURL } from "../common/serviceUtils";
import { IMessageStatus, INewSendMessage } from "../models/MessageModel";
import { AppService } from "./app.services";

class MessageService {
  getVehicleList() {
    return AppService.axios.get(getMessagingURL("vehicles"));
  }
  getVehicleMessageList() {
    return getMessagingURL("v1/vehicle-messages");
  }
  getVehicleMessageThreads(terminalId: string) {
    return AppService.axios.get(getMessagingURL(`vehicle/${terminalId}`));
  }
  getMessageTypes() {
    return AppService.axios.get(getMessagingURL("v1/messagetypes"));
  }
  uploadFile(formData: FormData) {
    return AppService.axios.post(getMessagingURL("attachment/upload"), formData, {
      transformRequest: (data?: any, headers?: AxiosRequestHeaders) => {
        if (headers) {
          headers["Content-Type"] = "multipart/form-data";
        }
        return data;
      },
    });
  }
  sendMessage(terminalId: string, newMessage: INewSendMessage) {
    return AppService.axios.post(
      getMessagingURL(`vehicle/message?terminalId=${terminalId}`),
      newMessage,
    );
  }
  updateMessageStatus(terminalId: string, messageIds: string[], status: IMessageStatus) {
    return AppService.axios.patch(getMessagingURL(`v1/vehicle-messages/${terminalId}`), {
      messageIds,
      status,
    });
  }
  getUnreadMessagesCount(terminalId?: string) {
    return AppService.axios.get(
      getMessagingURL(`vehicles/unread${terminalId ? `?terminalId=${terminalId}` : ""}`),
    );
  }
  getTotalUnreadMessagesCount() {
    return AppService.axios.get(getMessagingURL(`unread`));
  }
  getAttachment(fileDownloadLink: string) {
    return AppService.axios.get(fileDownloadLink);
  }
  getFileMaxSize() {
    return AppService.axios.get(getMessagingURL("attachment/doc-size"));
  }
}

export default MessageService;
