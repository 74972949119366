import React from "react";
import TracesListPanel from "../../../organisms/Traces/TracesListPanel/TracesListPanel";
import TwoColumnTemplate from "../../../../global/template/TwoColumnTemplate/TwoColumnTemplate";
import TwoRowTemplate from "../../../../global/template/TwoRowTemplate/TwoRowTemplate";
import TracesTimeline from "../../../organisms/Traces/TracesTimeline/TracesTimeline";
import TracesMapDesktopContainer from "../TracesMapDesktopContainer/TracesMapDesktopContainer";

const TracesContentContainer = () => {
  return (
    <TwoColumnTemplate
      leftPane={{
        component: (
          <TwoRowTemplate
            topPane={{
              component: <TracesMapDesktopContainer />,
              rowHeight: "h-100",
            }}
            bottomPane={{
              component: <TracesTimeline />,
              rowHeight: "h-25",
            }}
          />
        ),
        colSpan: 9,
      }}
      rightPane={{
        component: <TracesListPanel />,
        colSpan: 3,
      }}
    />
  );
};

export default TracesContentContainer;
