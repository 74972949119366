import React, { useEffect, useState } from "react";
import { ITraceVehicle } from "../../../../../models/TraceModel";
import "./TraceVehiclesList.scss";
import TraceService from "../../../../../services/Trace.service";
import AutoComplete, { IListData } from "../../../../molecules/AutoComplete/AutoComplete";
import { useNavigate, useParams } from "react-router";
import { DATE_DISPLAY_FORMAT, PATH } from "../../../../../common/constants";
import FleetIcon from "../../../../molecules/FleetIcon/FleetIcon";
import { useEventSource } from "../../../../../hooks/useEventSource/useEventSource";
import moment from "moment";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { observer } from "mobx-react-lite";
import { sendMonitoringLogs } from "../../../../../common/utils";

const TraceVehiclesList = () => {
  const isMobile = useMobileDetect();
  const params = useParams();
  const traceService = new TraceService();
  const traceStore = useTraceStore();
  const navigate = useNavigate();
  const [showError, setShowError] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(true);
  const [selectedUnitId, setSelectedUnitId] = useState<string>();
  const [traceVehiclesList, setTraceVehiclesList] = useState<IListData[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>();
  const formatData = (data: ITraceVehicle) => {
    const id = data?.id || "";
    const icon = data?.type ? <FleetIcon type={data.type} /> : undefined;
    const title = data?.name || "";
    return { id, icon, title };
  };
  const handleListSelect = (listItem: IListData) => {
    try {
      const date = traceStore?.traceFilter?.selectedDate || moment();
      sendMonitoringLogs("HISTORY_VEHICLES_LIST_SELECT");
      navigate(`${PATH.TRACES}/${listItem.id}/${date.format(DATE_DISPLAY_FORMAT)}`);
    } catch (error) {
      console.log("TraceVehiclesList ~ handleListSelect ~ error:", error);
    }
  };
  const [source, data, isCompleted, error] = useEventSource({
    src: {
      url: traceService.getTraceVehiclesList(),
    },
  });
  const handleListResponse = (data: ITraceVehicle) => {
    if (data) {
      const formattedData = formatData(data);
      if (!isMobile && !params?.unitId && traceVehiclesList?.length === 0) {
        handleListSelect(formattedData);
      }
      setTraceVehiclesList([...traceVehiclesList, formattedData]);
    }
  };
  useEffect(() => {
    if (data) {
      setShowLoading(false);
      setShowError(false);
      handleListResponse(data);
    }
  }, [data]);

  useEffect(() => {
    if (error && traceVehiclesList.length === 0) {
      setShowError(true);
    }
  }, [error]);
  useEffect(() => {
    if (isCompleted) {
      setShowLoading(false);
      if (traceVehiclesList.length === 0) {
        setShowError(true);
        setErrorMsg("TTM.followup.error.noDataAvailable");
        traceStore?.setVehiclesList([]);
      } else {
        traceStore?.setVehiclesList(traceVehiclesList);
      }
    }
  }, [isCompleted]);
  useEffect(() => {
    setSelectedUnitId(params?.unitId);
    if (!isMobile && !params?.unitId && traceVehiclesList.length > 0) {
      traceVehiclesList[0] && handleListSelect(traceVehiclesList[0]);
    }
  }, [params?.unitId]);
  return (
    <div className="list-block">
      {errorMsg ? (
        <div className="error-msg">{i18nInstance.t(errorMsg)}</div>
      ) : (
        <AutoComplete
          showError={showError}
          showLoader={showLoading}
          list={traceVehiclesList}
          onListSelect={handleListSelect}
          rowHeight={46}
          selectedItemId={selectedUnitId}
        />
      )}
    </div>
  );
};
export default observer(TraceVehiclesList);
