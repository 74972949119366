import React from "react";
import "./VehicleTemperature.scss";
import Dash from "../../atoms/Dash/Dash";
import { isWarning } from "../../../common/utils";
import warningIcon from "../../../assets/images/warning.svg";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { MEASUREMENT_UNITS } from "../../../common/constants";
import { ITemperatureZone } from "../../../models/VehicleModel";

export interface IVehicleTemperatureProps {
  zones: ITemperatureZone[];
  tempCls?: string;
}
const defaultProps: IVehicleTemperatureProps = {
  zones: [],
};
const VehicleTemperature = (props: IVehicleTemperatureProps) => {
  const { zones, tempCls } = props;
  const zoneList =
    zones && zones.length > 0 ? (
      zones.map((zoneItem: ITemperatureZone, index: number) => {
        const { zone: zoneLabel, temp, high, low } = zoneItem;
        const warning = isWarning(temp, low, high);
        const warningStyle = warning ? "warning" : "";
        const icon = warning ? (
          <img
            src={warningIcon}
            className="warningIcon ml-1"
            title={i18nInstance.t("TTM.followup.warning")}
          />
        ) : null;
        const zoneTemp = temp || temp === 0 ? `${temp} ${MEASUREMENT_UNITS.TEMPERATURE}` : "";
        const separator = zoneLabel ? ":" : "";

        return (
          <div key={index} className={`d-flex flex-column zone ${warningStyle} `}>
            <div className="d-flex">
              {zoneLabel && (
                <span className={`truncate-text zone-label`} title={`${zoneLabel}`}>
                  {zoneLabel}
                </span>
              )}
              {separator}
              <span className={`truncate-text zone-value`} title={`${zoneTemp}`}>
                {zoneTemp}
                {icon}
              </span>
            </div>
          </div>
        );
      })
    ) : (
      <Dash />
    );
  return <div className={`d-flex flex-row zone-wrapper row-gap ${tempCls}`}>{zoneList}</div>;
};
VehicleTemperature.defaultProps = defaultProps;
export default VehicleTemperature;
