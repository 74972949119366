import React from "react";
import "./TwoRowTemplate.scss";

export type RowHeight = "h-25" | "h-50" | "h-75" | "h-100";

export interface IRow {
  component: React.ReactElement;
  rowHeight: RowHeight;
}

export interface IProps {
  topPane: IRow;
  bottomPane: IRow;
}

const TwoRowTemplate = (props: IProps) => {
  const { topPane, bottomPane } = props;
  return (
    <div className="two-row-container">
      <div className={topPane.rowHeight}>{topPane.component}</div>
      <div className={bottomPane.rowHeight}>{bottomPane.component}</div>
    </div>
  );
};

export default TwoRowTemplate;
