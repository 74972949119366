import React from "react";
import TwoColumnTemplate from "../../../../global/template/TwoColumnTemplate/TwoColumnTemplate";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";

const TracesDesktopContainer = () => {
  return (
    <TwoColumnTemplate
      leftPane={{
        component: (
          <div className="d-flex h-100 justify-content-center">
            <Preloader />
          </div>
        ),
        colSpan: 6,
      }}
      rightPane={{
        component: (
          <div className="d-flex h-100 justify-content-center">
            <Preloader />
          </div>
        ),
        colSpan: 6,
      }}
    />
  );
};

export default TracesDesktopContainer;
