import React from "react";
import "./PanelHeader.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { ReactComponent as BackIcon } from "../../../../assets/images/arrow_back.svg";

export interface IPanelHeader {
  title?: string;
  onBackClick?: () => void;
}

const PanelHeader = (props: IPanelHeader) => {
  const { title, onBackClick } = props;
  return (
    <div className="panel-header">
      <BackIcon
        className="panel-header-back"
        title={i18nInstance.t("TTM.followup.back")}
        onClick={onBackClick}
      />
      <div className="panel-header-title">{title}</div>
    </div>
  );
};

export default PanelHeader;
