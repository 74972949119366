import React from "react";
import moment from "moment";
import "./TracesMapDesktopContainer.scss";
import { useNavigate, useOutletContext } from "react-router-dom";
import TracesMap from "../../../organisms/Traces/TracesMap/TracesMap";
import { ITraceOutletContext } from "../../../../../models/TraceModel";
import { PATH, DATE_DISPLAY_FORMAT } from "../../../../../common/constants";
import CustomDatePicker from "../../../molecules/CustomDatePicker/CustomDatePicker";

const TracesMapDesktopContainer = () => {
  const navigate = useNavigate();
  const mapContext = useOutletContext<ITraceOutletContext>();

  const handleOnDateSelect = (date: Date | null) => {
    try {
      date &&
        navigate(
          `${PATH.TRACES}/${mapContext?.terminalId}/${moment(date).format(DATE_DISPLAY_FORMAT)}`,
        );
    } catch (error) {
      console.log("TracesMapDesktopContainer ~ handleOnDateSelect ~ error:", error);
    }
  };

  return (
    <div className="traces-map-desktop-container">
      <div className="datepicker-wrapper flex-center">
        <CustomDatePicker
          selectedDate={mapContext?.traceFilter?.selectedDate}
          onDateSelect={handleOnDateSelect}
        />
      </div>
      <TracesMap />
    </div>
  );
};

export default TracesMapDesktopContainer;
